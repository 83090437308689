@use "sass:map";
@use "@angular/material" as mat;
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';

@include mat.core();

@import "../../themes/dark.scss";

@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300&display=swap");


$my-primary: (
  50: #eeeafb,
  100: #d2caf5,
  200: #ecebff,
  300: #9484eb,
  400: #5b3cc6,
  500: #5c4ee1,
  600: #5049da,
  700: #3d41d0,
  800: #293bc8,
  900: #2c2466,
  contrast: (50: rgba(#ffffff, 0.87),
    100: rgba(#ffffff, 0.87),
    200: rgba(#ffffff, 0.87),
    300: rgba(#ffffff, 0.87),
    400: rgba(#ffffff, 0.87),
    500: rgba(#ffffff, 0.87),
    600: rgba(#ffffff, 0.87),
    700: rgba(#ffffff, 0.87),
    800: rgba(#ffffff, 0.87),
    900: rgba(#ffffff, 0.87),
    A100: rgba(#ffffff, 0.87),
    A200: rgba(#ffffff, 0.87),
    A400: rgba(#ffffff, 0.87),
    A700: rgba(#ffffff, 0.87),
  ),
);
$my-accent: (
  50: #f2eff0,
  100: #eac7d7,
  200: #e8a7c4,
  300: #e995bb,
  400: #ea81b0,
  500: #ed79ae,
  600: #ec61a0,
  700: #ec4690,
  800: #ea1877,
  900: #e7046a,
  contrast: (50: rgba(#ffffff, 0.87),
    100: rgba(#ffffff, 0.87),
    200: rgba(#ffffff, 0.87),
    300: rgba(#ffffff, 0.87),
    400: rgba(#ffffff, 0.87),
    500: rgba(#ffffff, 0.87),
    600: rgba(#ffffff, 0.87),
    700: rgba(#ffffff, 0.87),
    800: rgba(#ffffff, 0.87),
    900: rgba(#ffffff, 0.87),
    A100: rgba(#ffffff, 0.87),
    A200: rgba(#ffffff, 0.87),
    A400: rgba(#ffffff, 0.87),
    A700: rgba(#ffffff, 0.87),
  ),
);

$my-primary: mat.define-palette($my-primary, 400, 200, 900);
$my-accent: mat.define-palette($my-accent, 500, 200, 900);

// $list-active: mat.define-palette($my-primary, 400);
$list-active: map.get($my-primary, 400);

.mat-mdc-list-item {
  .mdc-list-item__content {
    a.active {
      background-color: $list-active;
    }
  }
}

// Background palette for light themes.
$light-theme-background-palette: (
  status-bar: map.get($my-primary, 300),
  app-bar: map.get($my-primary, 100),
  background: blue,
  hover: rgba(black, 0.04),
  // TODO(kara): check style with Material Design UX
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  // focused-button: $dark-focused,
  selected-button: map.get($my-primary, 300),
  selected-disabled-button: map.get($my-primary, 400),
  disabled-button-toggle: map.get($my-primary, 200),
  unselected-chip: map.get($my-primary, 300),
  disabled-list-option: map.get($my-primary, 200),
  tooltip: map.get($my-primary, 700),
);

// .mat-mdc-list-item {
//   .mdc-list-item__content {
//     a.active {
//       background-color: palette.$light-theme-background-palette;
//     }
//   }
// }

// Background palette for dark themes.
$dark-theme-background-palette: (
  status-bar: black,
  app-bar: map.get($my-primary, 900),
  background: purple,
  hover: rgba(white, 0.04),
  // TODO(kara): check style with Material Design UX
  card: map.get($my-primary, 800),
  dialog: map.get($my-primary, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map.get($my-primary, 800),
  // focused-button: $light-focused,
  selected-button: map.get($my-primary, 900),
  selected-disabled-button: map.get($my-primary, 800),
  disabled-button-toggle: black,
  unselected-chip: map.get($my-primary, 700),
  disabled-list-option: rgba(white, 0.12),
  tooltip: map.get($my-primary, 700),
);

//typography
$my-custom-level: mat.define-typography-level($font-family: Poppins,
    $font-weight: 400,
    $font-size: 1rem,
    $line-height: 1,
    $letter-spacing: normal,
  );

$my-custom-typography-config: mat.define-typography-config($headline-2: mat.define-typography-level(60px, 56px, 700),
    /* .mat-headling-2 */
    $headline-4: mat.define-typography-level(36px, 40px, 700),
    /* .mat-headling-4 */
    $headline-5: mat.define-typography-level(24px, normal, 600),
    /* h1, headline .mat-headline-5*/
    $subtitle-1: mat.define-typography-level(18px, 21px, 500),
    /* h3,.mat-subtitle-1*/
    $subtitle-2: mat.define-typography-level(16px, 20px, 400),
    /* h4, sub-heading .mat-subtitle-2*/
    $body-1: mat.define-typography-level(14px, 20px, 400),
    /* table-content .mat-body-1*/
    $caption: mat.define-typography-level(18px, 20px, 500),
    /* subtext .mat-caption*/
  );

$my-typography: $my-custom-typography-config;

// Define a light theme
$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: $my-typography,
      density: 0,
    ));

@include mat.all-component-themes($my-theme);
@include mat.typography-hierarchy($my-typography);
@include mat.all-component-themes($my-theme);
@include mat.icon-theme($my-theme);

@import "./components/color.scss";
@import "./components/variables.scss";
@import "./components/typography";
@import "./components/mixins";
@import "./components/base";
@import "./components/normalize";
@import "./components/_buttons.scss";
@import "./components/icons-material-design";
@import "./components/material-base";
@import "./components/custom";
@import "./components/global";
@import "./components/grid";
@import "./components/storybook";
@import "./components/pages/signin";
@import "./components/pages/account-detail";
@import "./components//pages/listing";
@import "./components/responsive";
@import "./components//pages/dashboard";
@import "./components//pages/customer-portal";

.mat-primary {
  &.mat-mdc-button-base {
    --mat-fab-foreground-color: #fff;
    --mdc-filled-button-label-text-color: #fff !important;
    --mdc-protected-button-label-text-color: #fff;
  }

  &.mat-mdc-fab,
  &.mat-mdc-mini-fab {
    --mat-icon-color: #fff;
  }
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;

  &.fw-light {
    font-variation-settings: "wght" 200;
  }
}


.admin-sidenav {
  .mat-drawer {
    width: 220px;
  }

}

.softobiz-sidenav {

  .mat-drawer-container {
    background-color: unset;

  }




  .mat-drawer-content {
    margin-left: 220px;
    transition-duration: none !important;
    background-color: white;
  }

  &.mat-drawer-transition .mat-drawer-content {
    transition-timing-function: inherit !important;
    transition-property: inherit !important;
    transition-duration: 100ms;
  }

  mat-sidenav.mat-drawer.mat-sidenav {
    transform: none !important;
    visibility: visible !important;
    // width: 100%;
    display: block !important;
    overflow: visible;
    border-radius: 0.5rem !important;
  }

  .mat-drawer-side {
    border-right-color: none;
    border-right-width: 0px;
  }

  .side-nav-logo {
    padding: 8px;
  }
}

.mat-drawer-opened {
  .mat-drawer-content {
    margin-left: 58px;
    transition-duration: none;
  }
}

.mat-sidenav-container.softobiz-sidenav.mat-drawer-transition.side-nav-open mat-sidenav {
  width: 58px;
}

.mat-sidenav {
  border: 0rem;
  width: 220px;
  transition: none !important;
}

.header-wrapper.mat-toolbar-single-row {
  padding: 16px 24px;
  height: 68px;
  border-radius: 0.5rem;
  width: auto;
  line-height: inherit;
}

.main-content {
  background-color: #f5fbff;
  height: calc(100vh - 68px);
  overflow: scroll;
  border-top-left-radius: 16px;

  &.listing-wrapper {
    height: calc(100vh - 68px);
    overflow: scroll;
  }
}

// customer-sidenav
.side-nav-open {
  mat-list-item {
    .mdc-list-item__content {
      display: flex;
      align-items: center;
      justify-content: center;

      .sidebar-menu {
        span {
          display: none;
        }
      }

    }
  }

}


.admin-sidenav {
  .mat-mdc-list-item {
    &.active-list {
      .sidebar-menu {
        img {
          filter: invert(34%) sepia(94%) saturate(1201%) hue-rotate(171deg) brightness(89%) contrast(87%);

        }

      }
    }
  }
}

.customer-sidenav {
  .mat-mdc-list-item {
    &.active-list {
      .sidebar-menu {
        img {
          filter: invert(34%) sepia(94%) saturate(1201%) hue-rotate(171deg) brightness(89%) contrast(87%);
        }

      }
    }
  }

  mat-sidenav.mat-drawer.mat-sidenav {
    width: 100%;
  }

}