@media (max-width: 1880px) {
    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .button-wrapper {
                        width: 198px;
                    }
                }
            }
        }
    }

    .account-detail-module {
        .expansion-wrapper {
            .finance-info {
                padding-right: 39px;

            }

            .horizontal-col {
                flex-wrap: wrap;
                justify-content: unset;

                .horizontal-information {
                    &.credit-info {
                        padding-left: 62px;
                        min-width: 239px;
                        margin-top: 20px;
                    }

                    &.timezone {
                        margin-top: 20px;
                    }


                }

                .button-wrapper {
                    margin-top: 20px;
                    display: none;

                    &.billing-button {
                        display: block;
                        margin-top: 0;
                    }

                }
            }
        }

    }
}

@media (min-width: 1881px) {
    .account-detail-module .expansion-wrapper .mat-expansion-panel-header {
        padding-right: 92px;
    }
}

@media (max-width: 1826px) {
    .account-detail-module {
        .expansion-wrapper {
            .finance-info {
                padding-right: 33px !important;

            }

        }


    }

    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {

                    .button-wrapper {
                        width: 176px;

                    }
                }
            }
        }
    }


}

@media (max-width: 1750px) {
    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .button-wrapper {
                        width: 109px;

                    }
                }
            }
        }
    }

}



@media (max-width: 1538px) {
    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .button-wrapper {
                        width: 164px;

                    }
                }
            }
        }
    }

    .account-detail-module {
        .expansion-wrapper {
            .horizontal-information {
                &.process-billing {
                    min-width: 15px;
                    width: 9%;

                }
            }

        }
    }

}


@media (max-width: 1477px) {
    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .detailed-layout {
                        margin-top: 0 !important;

                        .finance-info {
                            padding-left: 11px;

                            p {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 157px;
                                display: block;
                            }
                        }

                        .status-info {
                            padding-left: 53px;
                        }

                    }

                    .horizontal-information {
                        width: 17%;

                        &.process-billing {
                            width: 224px;
                        }

                        &.credit-info {
                            padding-left: 24px !important;
                        }

                        &.timezone {
                            padding-left: 0px !important;
                        }

                    }

                    .button-wrapper {
                        width: 83px;
                    }
                }
            }
        }
    }


    .account-detail-module {
        .expansion-wrapper {
            .finance-info {
                padding-right: 13px !important;
            }

            .horizontal-information {
                &.process-billing {
                    padding-left: 62px;
                    margin-top: 20px;
                    min-width: 166px;
                    width: 15%;
                }

                &.credit-info {
                    padding-left: 75px !important;
                    min-width: 170px !important;
                }



            }

            .button-wrapper {
                &.currency {
                    display: block;
                    margin-top: 0;
                }

                &.billing-button {
                    display: none !important;
                }

                &.hide-button {
                    display: none;
                }
            }
        }
    }
}


@media (max-width: 1400px) {
    .invoicing-listing {
        .invoice-no {
            min-width: 100px;
        }

        .no-col {
            min-width: 70px;
            max-width: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .invoice-title {
            min-width: 100px;

        }

        .cost-centre {
            min-width: 140px;

        }

        .subject-col {
            max-width: 143px !important;
            min-width: 143px;

            &.type-col {
                min-width: 140px;

            }


        }

    }

    .account-detail-module {
        .expansion-wrapper {
            .finance-info {
                padding-right: 0px !important;

            }

            .horizontal-information {
                &.credit-info {
                    min-width: 154px !important;
                }

                &.date-col {
                    width: 19%;
                }

                &.currency-data {
                    width: 17%;
                }
            }

        }

    }

    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .button-wrapper {
                        width: 195px;
                    }
                }
            }
        }
    }



    .account-detail-module .expansion-wrapper .horizontal-information {
        min-width: 154px;
        width: 14%;
    }

    .admin-invoicing-listing {
        height: calc(100vh - 235px);
    }

    .customer-listing {
        .account-detail-table {
            .account {
                min-width: 135px !important;
            }

            .cost {
                min-width: 159px !important;
            }

            .date {
                min-width: 171px !important;

            }

            .name-col {
                min-width: 100px;
                max-width: 100px;

            }

        }
    }



    .customer-resource {
        .email-info {
            min-width: 120px;
            max-width: 120px;
        }

        .name-info-col {
            min-width: 100px;
            max-width: 100px;
        }
    }

    .customer-invoicing {
        .subject-col {
            max-width: 400px;
        }

        .type-col {
            max-width: 200px;
        }
    }

    .listing-wrapper {
        &.custom-management-wrapper {
            .resource-col {
                max-width: 122px;

            }

            .cost-center-col {
                max-width: 144px;
            }

            .date {
                min-width: 90px !important;
            }

            .project-col {
                max-width: 144px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            .project-name-col {
                max-width: 100px;
                display: block;

            }

            .project-cost {
                max-width: 110px;
                min-width: 110px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.model {
                    max-width: 155px;
                    min-width: 155px;
                }
            }

            .date-col {
                min-width: 113px;
            }

            .project-role-col {
                max-width: 100px !important;
            }

        }
    }

    .invoice-listing {
        .invoice-no {
            min-width: 120px;
        }


        .updated {
            min-width: 150px;
        }

        .title-col {
            max-width: 381px;
        }

        .invoice-type-col {
            max-width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


    }

    .resource-detail {
        .name-col {
            max-width: 120px;
        }

        .table-info {
            .Status-info {
                width: 100px;
            }

        }
    }

    .resource-listing {
        .name-info-col {
            max-width: 200px;
        }

    }



    .dashboard-header-wrapper {
        .col {
            &.card {
                width: 25% !important;
            }
        }
    }

    .dashboard-content {
        .col {
            &.project-info {
                margin-top: 20px;
                width: 50%;

                &.project {
                    order: 1;
                }
            }

            &.project-data {
                width: 100%;
            }
        }

    }

    .customer-open-position {
        .cost-type-col {
            max-width: 120px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .role-type {
            min-width: 200px !important;
        }

        .cost-type {
            min-width: 117px !important;

        }
    }



    .listing-wrapper {
        .cost-leader-col {
            max-width: 156px;
        }

        .dashboard-wrapper {
            .name {
                min-width: 100px !important;

            }

            .role {
                min-width: 146px !important;
            }

        }
    }



    .project-detail {
        .title-col {
            min-width: 154px;
        }

        .type-info-col {
            max-width: 154px;
        }
    }


    .cost-listing {
        .name {
            min-width: 121px;
        }

        .name-col {
            max-width: 115px;

        }
    }

    .user-listing {
        .account-detail-table {
            .Description {
                min-width: 215px;
            }

            .Description-col {
                max-width: 215px;
            }

            .name {
                min-width: 115px;
                width: 115px;
            }

            .name-col {
                width: 120px;
                min-width: 120px;
                max-width: 120px;

            }

            .email {
                min-width: 185px;
            }

            .email-col {
                max-width: 185px;
            }

            .contact {
                min-width: 115px;
            }

            .status {
                min-width: 114px;
            }

            .updated {
                min-width: 148px;
            }
        }
    }

    .craft-listing {
        .name-info {
            min-width: 117px;
        }

        .name-col {
            max-width: 57px;

        }

        .email {
            min-width: 130px;

        }

        .email-col {
            max-width: 130px;
        }

        .notice-period {
            min-width: 95px;

        }

        .application-date {
            min-width: 141px;
        }

        .status-info {
            min-width: 141px;
        }
    }

    .project-listing {
        .name {
            min-width: 142px;
        }

        .project-name-col {
            max-width: 85px;
        }

        .cost-name {
            min-width: 120px;
        }

        .date {
            min-width: 90px;

        }

        .modal {
            min-width: 151px;
        }

        .status {
            min-width: 130px;

        }
    }

    .acquisitions-listing {
        .type-info {
            min-width: 98px;
        }

        .account-name {
            max-width: 122px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

        .title {
            min-width: 102px;

        }

        .designation {
            max-width: 102px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

    }

    .listing-wrapper {
        .listing-name {
            min-width: 155px;
        }

        .cost-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 155px;

        }

        .listing-code {
            min-width: 90px;
        }

        .updated-col {
            min-width: 113px;
        }

        .rate {
            min-width: 135px;

        }
    }

    .account-detail-module {
        .name-col {
            min-width: 120px;
        }

        .project-col {
            min-width: 118px;
        }

        .table-data {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;

        }
    }


    .customer-wrapper {
        .customer-name {
            min-width: 211px;
        }

        .customer-name-col {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;

        }

        .account-manager-col {
            max-width: 187px;
        }

        .client-info-col {
            max-width: 144px;
        }

        .date {
            min-width: 122px;
        }

        .review {
            min-width: 137px;
        }

        .currency-info {
            min-width: 78px;

        }
    }

    .custom-action-table.mat-mdc-table .action-table-header-data .mdc-data-table__cell,
    .custom-action-table.mat-mdc-table .action-table-header-data .mdc-data-table__header-cell {
        padding-right: 0;
    }

    .account-detail-module {

        .account-detail-table .account-detail-header .mdc-data-table__cell,
        .account-detail-table .account-detail-header .mdc-data-table__header-cell {
            padding: 8px;
        }

        &.account-style {
            .action-col {
                text-align: center;
            }

            .code-info-col {
                max-width: 154px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }

            .expense-col {
                max-width: 133px;
            }

            .leader-col {
                max-width: 160px;
            }

            .code-col {
                min-width: 154px;
                padding-left: 0;
                padding-right: 0;

                &.leader {
                    min-width: 160px;
                }

                &.rate {
                    min-width: 140px;
                }
            }
        }
    }


    .dashboard-header-wrapper {
        .col {
            &.card {
                width: 50%;


            }
        }

    }

    .listing-wrapper {
        .inputs-wrapper {
            margin-top: 20px;
        }

        .input-outer-wrapper {
            margin-top: 20px;

        }
    }

    .angular-stepper {
        .mat-horizontal-stepper-wrapper .mat-step-header[aria-selected=true] {
            padding: 7px 8px;
        }

        .mat-horizontal-stepper-wrapper .mat-step-header {
            padding: 7px 8px;
        }

        .heading {
            font-size: 12px;
        }

        .stepper-icon {
            height: 16px;
            width: 16px;

        }

        .material-symbols-outlined {
            font-size: 16px;

        }

        .material-icons-outlined {
            font-size: 16px;
        }

    }

    .header-button {
        font-size: 13px;

    }

    .header-button-wrapper {

        .header-button {
            button {
                font-size: 12px;

            }
        }
    }
}

@media (max-width: 1307px) {
    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .horizontal-information {
                        &.process-billing {
                            padding-left: 87px !important;
                            min-width: 150px !important;
                            width: 13%;

                        }
                    }
                }
            }
        }
    }


    .account-detail-module {
        .expansion-wrapper {
            .horizontal-information {
                &.date-col {
                    min-width: 170px;
                }
            }

            .button-wrapper {
                &.currency {
                    display: none;
                }

                &.date {
                    display: block;
                    margin-top: 0;
                }

            }

            .hidden-layout {
                margin-top: 14px !important;
            }

            .horizontal-col {
                .horizontal-information {
                    &.currency-data {
                        padding-left: 62px;
                        margin-top: 0px;
                        min-width: 100px !important;
                        width: 10%;
                        display: block;
                    }

                    &.credit-info {
                        padding-left: 4px !important;
                        min-width: 150px !important;

                    }

                    &.process-billing {
                        padding-left: 87px !important;
                        min-width: 150px !important;

                    }
                }

            }

        }
    }
}


// this is for resource page
@media (max-width: 1366px) {
    .custom-management-wrapper {
        .opening-col {
            min-width: 100px;
            display: block;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;

        }

    }

    .dashboard-wrapper {
        .info-container {
            .name-detail {
                min-width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 157px;
            }

            .project-avatar-detail {
                min-width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;

            }
        }
    }

    .account-detail-module {
        .edit-button {
            display: block !important;
            position: absolute;
            right: 25px;
        }

        .button-wrapper {
            &.currency {
                display: none !important;
            }

        }

        .layout-detail-info {
            .manager-info {
                display: block;
            }

            .finance-info {
                min-width: 120px !important;
                margin-right: 41px !important;
                width: 18% !important;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 157px;
                    display: block;
                }
            }


        }

        .detailed-layout {
            .manager-info {
                display: none !important;
            }

            .finance-info {
                display: none;
            }

            .status-info {
                display: none;
            }
        }

        .expansion-wrapper {
            .address-info {
                width: 200px;
                margin-right: 0 !important;
                margin-left: 0 !important;

                h2 {
                    margin-bottom: 0 !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    width: 149px;
                    white-space: nowrap;
                }

                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 149px;
                    display: block;
                }
            }

            .horizontal-information {
                width: 15%;

                h3 {
                    line-height: 32px;
                    margin-bottom: 0 !important;
                }

                &.timezone {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 133px;
                        display: block;
                    }
                }
            }

        }
    }

    .softobiz-sidenav {
        &.side-nav-open {
            .account-detail-module {
                mat-expansion-panel-header[aria-expanded="true"]+div {
                    .manager-info {
                        min-width: 120px !important;
                        width: 16.7% !important;
                    }

                    .finance-info {
                        padding-left: 0px;
                        min-width: 120px;
                        margin-right: 56px !important;
                        width: 16.7% !important;
                    }
                }

                .expansion-wrapper {
                    .horizontal-information {
                        width: 16.7% !important;

                        &.process-billing {
                            padding-left: 56px !important;
                            min-width: 200px !important;
                        }

                        &.credit-info {
                            min-width: 166px !important;
                            width: 16.7% !important;
                            padding-left: 0 !important;
                        }

                        &.timezone {
                            min-width: 166px !important;
                            padding-left: 0 !important;
                            width: 16.7% !important;

                        }
                    }
                }
            }
        }
    }


    .account-horizontal-layout {
        mat-expansion-panel-header[aria-expanded="true"]+div {
            .horizontal-information {
                &.process-billing {
                    width: 18% !important;
                    min-width: 200px !important;
                    padding-left: 56px !important;

                }

                &.credit-info {
                    width: 16% !important;
                    min-width: 120px !important;
                    padding-left: 0px !important;

                }

                &.timezone {
                    width: 17% !important;
                    min-width: 120px !important;
                    padding-left: 0px !important;

                }
            }

            .manager-info {
                display: block;
                min-width: 120px !important;
                margin-right: 0;
                padding-left: 0px;
                width: 18% !important;

                &.account-info {
                    display: block !important;

                }

                p {
                    word-wrap: break-word;
                }
            }

            .finance-info {
                &.detail {
                    display: block !important;
                }

            }

            .detailed-layout {
                margin-top: 20px !important;
                padding-left: 56px !important;

                .finance-info {
                    min-width: 171px;
                    width: 18%;

                }

                .status-info {
                    width: 18%;
                    min-width: 171px;
                    padding-left: 0px;
                }


            }

        }

        .expansion-wrapper {
            .horizontal-information {
                width: 16%;
                min-width: 120px;

                &.start-date {
                    width: 17%;
                    min-width: 120px;

                }
            }
        }

        .manager-info {
            &.show {
                display: block;
            }
        }
    }

    .softobiz-sidenav {
        .expansion-wrapper {
            .button-wrapper {
                width: unset;
            }


        }

        &.side-nav-open {
            .account-detail-module {
                .expansion-wrapper {
                    .detailed-layout {
                        .status-info {
                            padding-left: 18px;
                        }
                    }

                    .button-wrapper {
                        width: 125px;
                        text-align: end;
                    }
                }
            }
        }
    }

    .account-detail-module {
        mat-expansion-panel-header[aria-expanded="true"]+div {
            .currency-data {
                display: block;
                width: 238px;
                padding-left: 56px !important;
            }

            .detailed-wrapper {
                margin-top: 0 !important;

                .finance-info {
                    width: 240px;
                }

                .status-info {
                    min-width: 150px;
                    width: 14%;

                }
            }

            .process-billing {
                display: block;
                min-width: 130px !important;
                width: 13% !important;
                padding-left: 17px !important;
                margin-top: 0 !important;
            }

            .credit-info {
                display: block;
                min-width: 138px !important;
                width: 17%;
            }

            .timezone {
                display: block;
                padding-left: 6px;
                min-width: 167px !important;
                width: 17%;
            }

            .manager-info {
                &.block-info {
                    display: block;
                }
            }

        }

        .expansion-wrapper {
            .horizontal-col {
                .horizontal-information {
                    &.client-info-col {
                        min-width: 130px;
                        width: 13%;
                    }

                    &.date-col {
                        min-width: 120px;
                        width: 18%;
                    }

                    &.review-date-col {
                        min-width: 136px;
                        width: 17%;
                    }

                    &.credit-info {
                        width: 13%;
                        min-width: 88px !important;
                    }


                    &.currency-data {
                        display: block;
                        width: 18%;
                        min-width: 120px !important;
                        padding-left: 0 !important;
                    }

                    &.process-billing {
                        display: none;

                    }

                    &.timezone {
                        display: none;

                    }

                    &.credit-info {
                        margin-top: 0;
                        display: none;
                    }

                }

            }

            .currency-data {
                min-width: 224px !important;
                padding-left: 20px !important;

            }


            .hide-data {
                display: none;
            }


            .horizontal-information {
                &.credit-info {
                    padding-left: 0 !important;
                }
            }

            .button-wrapper {
                display: block;
                margin-top: 0 !important;
                margin-left: 90px !important;

                &.hide-button {
                    display: none;
                }

                &.credit-side-button {
                    width: 199px;
                    text-align: end;
                    display: block;
                    margin-left: 0 !important;
                }
            }
        }
    }

    .permission-listing-wrapper {
        .Permission-col {
            min-width: 350px;
        }

        .module-col {
            min-width: 200px;
        }

    }



    .listing-wrapper {
        .button-wrapper {
            margin-top: 20px;
        }
    }

    .main-content {
        .table-container {
            margin-top: 20px
        }
    }


}


@media (max-width: 1120px) {
    .financial-data .financial-info-col {
        &.style {
            margin-bottom: 16px;

        }

        width: 50% !important;
        max-width: 50%;

    }
}



// this is for the edit account detail page 
@media (max-width: 992px) {
    .account-wrapper {
        .financial-data .financial-info-col {
            &.style {
                margin-bottom: 0px !important;

            }
        }

        .input-text {
            margin-top: 0 !important;
        }

        .input-info {
            margin-top: 24px;
        }
    }
}