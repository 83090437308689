// side pannel
.pannel-container {
    .date-wrapper {
        min-width: 182px;
    }

    .resource-pagination {
        .account-detail-body {
            margin-bottom: 8px;
        }

        .mat-mdc-paginator {
            position: fixed;
            width: 100%;
            bottom: 0;

            .mat-mdc-paginator-outer-container {
                position: fixed;
                right: 0;

            }

        }
    }

    &.resource-modal-wrapper {
        .mat-drawer-inner-container {
            padding: 0 24px 24px 24px;

        }

    }

    &.resource {
        .mat-drawer-inner-container {
            width: 560px;
        }

    }

    .time-info {
        width: 82px;
    }

    .mat-drawer.mat-drawer-end {
        border-radius: 12px 0px 0px 0px;
    }

    .modal-header {
        position: sticky;
        top: 0;
        padding-top: 32px;
        z-index: 100;
        background: #FFF;

        &.resource {
            padding-top: 24px;

        }
    }

    .mat-drawer-inner-container {
        overflow: auto;
        width: unset;
        height: 94%;
        padding: 0 24px 24px 24px;
        background: #FFF;

    }

    .content-wrapper {
        &.resource-info {
            height: calc(100% - 90px);
            padding: 0 71px 0;

            &.invoice-listing{
                height: unset;
            }

            .listing-info-detail{
                height: calc(100vh - 327px);
            }

            &.resource-listing {
                padding: 0;

                .search-block {
                    width: 100%;
                    max-width: 379px;
                }
            }

        }

        .content-header {
            background-color: $border-bg;
            padding: 8px;
        }
    }
}

.account-detail-tab {
    .mat-mdc-paginator-container {
        bottom: 0;
        position: absolute;
        margin: 0;
        background: white;
    }

    .mat-mdc-tab-body {
        .mat-mdc-tab-body.mat-mdc-tab-body-active {
            overflow-y: clip;
        }

        .mat-mdc-tab-body-content {
            overflow: hidden;
        }
    }
}



// resource-listing
.resource-info-wrapper {
    height: calc(100vh - 233px);
}

.resource-listing {
    .name-info {
        min-width: 220px;
    }

    .name-info-col {
        max-width: 200px;
    }

    .role {
        min-width: 239px;
    }

    .date {
        min-width: 167px;
    }

    .action {
        width: 67px;
    }

}

.listing-wrapper {
    .listing-code {
        min-width: 173px;
    }

    .cost-leader-col {
        max-width: 200px;

    }

    .code-col {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        max-width: 120px;
    }

    .listing-name {
        min-width: 300px;
    }

    .rate {
        min-width: 168px;

    }

    .updated-col {
        min-width: 220px;

    }

    .input-wrapper {
        .status-box {
            max-width: 160px;

            &.project-box {
                max-width: 222px;

            }
        }
    }

    .listing-content {
        .New {
            color: $link-color;
            background-color: color("blue", "light");
        }

        .InProgress {
            background-color: color("orange", "lighten-1");
            color: $Warning-color;
        }

        .Closed {
            background: #F5F5F5;
            color: color("black", "lighten-1");
        }

        .candidate-status {
            padding: 0px 8px;
            border-radius: 4px;
            width: fit-content;
        }
    }
}

mat-option {
    .mat-pseudo-checkbox {
        display: none !important;
    }
}

.select-box {
    max-width: 199px;

    .mat-mdc-select-arrow {
        display: none;
    }
}


.overlay-info.mdc-list-item {
    padding-left: 8px !important;
    padding-right: 8px !important;
    min-width: 170px;
}

.cdk-overlay-pane {
    .mat-mdc-select-panel {
        height: 100%;
        max-height: 419px;
    }
}


.multiple-select-option {
    span {
        width: 100%;
    }

    &[aria-selected="true"] {
        .mat-mdc-checkbox {
            opacity: 1;
        }

    }

    .mat-mdc-checkbox {
        opacity: 0;
    }

}

.resource-detail {
    .left-content {
        .table-wrapper {
            outline: none;

            tr {
                .identity {
                    width: 120px;
                    text-align: left;
                    font-weight: 400;
                }
            }
        }

        width: 346px;
        padding: 11px 19px;
        border-radius: 8px;
        background: #FFF;

    }

    .table-info {
        .name-col {
            max-width: 120px;
            // display: block;
        }


        // width: calc(100% - 346px);
        // min-width: calc(100% - 387px);
        background-color: #FFF;
        padding: 11px 19px;

        .custom-action-table.mat-mdc-table .action-table-header-data .mat-mdc-header-cell {
            border-radius: 2px;
            border-bottom: 1px solid $border-line;

        }


        .Status-info {
            width: 173px;
        }

        .date-info {
            width: 126px;

        }

        .mdc-data-table__row:last-child .mat-mdc-cell {
            border-radius: 2px;
            border-bottom: 1px solid $border-line;

        }

        .listing-content {
            .Ongoing {
                color: $success-color;
                background-color: color("green", "light-1");
            }

            .Completed {
                color: $link-color;
                background-color: color("blue", "light");
            }

            .account-status {
                border-radius: 4px;
                width: fit-content;
            }
        }
    }

    .img-wrapper {
        .backgound-img {
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px 8px 0px 0px;
            }
        }

        .img-content {
            background-color: #FFF;
            padding: 15px 0 25px;
        }

        .profile-img {
            bottom: 25px;
            left: 38px;
            height: 126px;
            overflow: hidden;
            width: 126px;
            border-radius: 10px;
            border: 3.566px solid #FFF;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

        }

        .profile-info {
            margin-left: 188px;
        }

    }

}

// open position 
.acquisitions-listing {
    .type-info {
        min-width: 180px;
    }

    .account-name {
        max-width: 175px;
        white-space: nowrap;
        text-overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;

    }

    .experience {
        min-width: 191px;
    }

    .date-info {
        min-width: 184px;
    }

    .candidate {
        min-width: 121px;

    }

    .status-info {
        min-width: 104px;
    }

    .title {
        min-width: 215px;
    }

}

.craft-listing {

    .Interview {
        color: $link-color;
        background-color: color("blue", "light");

    }

    .offered {
        background-color: color("orange", "lighten-1");
        color: $Warning-color;

    }

    .Pre-Onboarding {
        background-color: color("green", "light-1");
        color: color("green", "base");
    }

    .name-info {
        min-width: 200px;
    }

    .resume-info {
        min-width: 245px
    }

    .resumne-icon {
        .material-symbols-outlined {
            font-size: 20px;
        }
    }


    .notice-period {
        min-width: 196px;
    }

    .application-date {
        min-width: 173px;

    }

    .email {
        min-width: 245px;
    }

    .number-info {
        min-width: 168px;
    }

    .status-info {
        min-width: 160px;
    }

    .action {
        min-width: 65px;
    }
}


.details-popup {
    max-width: 633px;

    .details-wrapper {
        .resource-info {
            width: 161px;
        }
    }
}

// admin-invoicing-listing 
.admin-invoicing-listing {
    .invoice-no {
        width: 126px;
    }

    .invoice-no-col {
        max-width: 80px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .title-col {
        max-width: 290px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

    }

    .invoice-type-col {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .amount-col {
        max-width: 115px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

    }

    .invoice-title {
        min-width: 330px;
    }

    .invoice-type {
        min-width: 200px;

    }

    .cost-centre {
        min-width: 190px;

    }

    .notice-period {
        min-width: 120px;
    }

    .amount {
        min-width: 130px;

    }

    .status {
        min-width: 137px;
    }


    .updated {
        min-width: 154px;
    }
}


// user-management
.user-listing-wrapper {
    height: calc(100vh - 347px);
}

.roles-listing-wrapper {
    height: calc(100vh - 360px);
}

.user-listing {
    .account-status {
        padding: 0px 8px 0px 8px;
        width: fit-content;
        border-radius: 4px;

    }

    .active-status {
        color: color("green", "base");
        background-color: color("green", "light-1");
    }

    .deactivated-status {
        color: $error-color;
        background-color: color("orange", "lighten-1");
    }

    .management {
        max-width: unset;
        background: unset;
    }

    .account-detail-table {
        .name-col {
            min-width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }

        .Description {
            min-width: 300px;
        }


        .serial-no {
            min-width: 51px;
        }

        .name {
            min-width: 264px;
            width: 264px;
        }

        .email {
            min-width: 288px;
        }

        .contact {
            min-width: 200px;
        }

        .role {
            min-width: 260px;

        }

        .status {
            min-width: 139px;
        }

        .updated {
            min-width: 198px;
        }

        .action {
            min-width: 70px;
        }
    }

}

.permission-listing-wrapper {
    .custom-action-table.mat-mdc-table .action-table-body-data {
        box-shadow: none;
    }

    .mdc-data-table__row:last-child .mat-mdc-cell {
        border-bottom: 1px solid $border-line;
    }

    background-color: #FFF;

    .mat-mdc-header-row {
        background-color: #F5FBFF;
    }

    .module-col {
        min-width: 329px;
    }

    .Permission-col {
        min-width: 160px;
    }
}

// invoicing listing
.invoicing-listing {
    height: calc(100vh - 280px);

    .invoice-no {
        min-width: 127px;

    }

    .subject-col {
        min-width: 400px;
        max-width: 400px;

        &.type-col {
            overflow: hidden;
            white-space: nowrap;
            min-width: 190px;
            max-width: 190px;

        }
    }


    .candidate-status {
        border-radius: 4px;
        width: fit-content;
    }

    .invoice-no {
        min-width: 126px;

    }

    .invoice-title {
        min-width: 400px;

    }

    .invoice-type {
        min-width: 200px;

    }

    .cost-centre {
        min-width: 200px;

    }

    .notice-period {
        min-width: 195px;

    }

    .amount {
        min-width: 112px;

    }

    .status {
        min-width: 160px;
    }

    .amount-col-info{
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .updated {
        min-width: 154px;
    }

    .action {
        min-width: 73px;

    }
}

.expense-wrapper {
    .content-wrapper {
        background: $border-bg;
        border-radius: 4px;
    }
}

// settings
.setting-wrapper {
    .account-detail-tab {
        &.empty {
            max-width: 594px;
            border-radius: 16px;
        }

        &.settings {
            max-width: 1012px;
            height: fit-content;

        }

        &.account-setting {
            max-width: 594px;
        }

    }
}

// loading wrapper
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgb(255 255 255 / 95%);

}

.loader {
    width: 189px;
    height: 4px;
    background-color: #F5FBFF;
    position: relative;
    overflow: hidden;
}

.inner-loader {
    width: 30px;
    height: 100%;
    background-color: color("skyblue", "base");
    position: absolute;
    left: 0;
    animation: move-left-right 1.5s linear infinite;
}

@keyframes move-left-right {
    0% {
        left: 0;
    }

    50% {
        left: calc(100% - 30px);
    }

    100% {
        left: 0;
    }
}



// this is for account-module
.customer-wrapper {
    .customer-name {
        min-width: 200px;

    }

    .customer-name-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;

    }

    .account-manager-col {
        max-width: 122px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .customer-address {
        min-width: 360px;
    }

    .account-manager {
        min-width: 186px;

    }

    .date {
        min-width: 136px;



    }

    .review {
        min-width: 151px;



    }

    .client {
        min-width: 144px;


    }

    .currency-info {
        min-width: 78px;



    }

    .update-col {
        min-width: 170px;

    }

    .action-col {
        min-width: 73px;

    }
}

.account-detail-module {
    &.account-style {
        .code-info-col {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .activity-listing {
        height: calc(100vh - 280px);
    }

    .code-col {
        min-width: 311px;
    }

    .action-col {
        min-width: 73px;

    }

    .name-col {
        min-width: 207px;
    }

    .project-col {
        min-width: 178px;
    }
}


// this is cost-center module
.cost-listing-wrapper {
    .candidate{
        min-width: 100px;
    }

    .candidate-col{
        min-width: 100px;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    height: calc(100vh - 220px);
}

.name-col {
    max-width: 240px;
}



.cost-listing {
    .activity-info {
        span {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }


    .name {
        min-width: 260px;
    }

    .account-detail-tab {
        .mat-mdc-tab-body.mat-mdc-tab-body-active {
            overflow-y: clip;
        }
    }


}


.activity-time {
    min-width: 130px;
}

.activity-discription {
    min-width: 341px;
}

.activity-info {
    min-width: 164px;
}



// this is project-management module
.Project-listing-wrappper {
    height: calc(100vh - 227px);
}



.project-listing {
    .name {
        min-width: 200px;
    }

    .project-name-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;

    }

    .modal {
        min-width: 200px;
    }

    .budget {
        min-width: 200px;

    }

    .date {
        min-width: 200px;

    }

    .status {
        min-width: 200px;
    }

    .updated {
        min-width: 200px;
    }
}


.project-detail {
    .title-col {
        min-width: 320px;

    }

    .account-detail-tab {
        .mat-mdc-tab-body.mat-mdc-tab-body-active {
            overflow-y: clip;
        }

    }

}


// resource-detail in customer portal
.customer-resource {
    .project-name {
        min-width: 220px;
    }

    .project-cost {
        min-width: 509px;
    }

    .cost-model {
        min-width: 240px;

    }

    .date-info {
        min-width: 126px;
    }

    .Status-info {
        min-width: 173px;
    }
}


.resource-modal-pannel {

    .mat-sort-header-arrow,
    [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow {
        margin: 0;
    }
}


.account-detail-module {
    .input-wrapper {
        .mat-mdc-select .mat-mdc-select-arrow {
            border-color: #FFF;
        }



        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
            border-color: #FFF;
        }

        .mat-mdc-select-value-text {
            color: #FFF;
            font-size: 14px;
        }

        .mat-mdc-select-placeholder {
            color: #FFF !important;

        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
            border-color: #30A4DC;
        }

        .mat-mdc-select-value {
            color: #FFF !important;
        }

        .status-box {
            &.core-status {
                .mat-mdc-text-field-wrapper {
                    width: 177px;
                    background: #30A4DC;
                    border-radius: 6px;
                }
            }
        }
    }


    .address-info {
        width: 224px;
        margin-right: 14px;
    }

    .expansion-wrapper {
        .horizontal-information {
            min-width: 170px;
            width: 14%;
        }

        .mat-focus-indicator {
            position: unset;
        }

        .mat-expansion-panel-header {
            height: fit-content !important;

        }

        .mat-expansion-panel:not([class*=mat-elevation-z]) {
            box-shadow: none;
        }

        .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
            background-color: #FFF;
        }

        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
            margin-right: 0;
        }

        .mat-expansion-indicator {
            bottom: -13px;
            position: absolute;
            right: 24px;
            background: rgb(48, 164, 220);
            border-radius: 50%;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                border-color: #FFF;
            }
        }

        .mat-expansion-panel {
            overflow: visible;
        }
    }

    .manager-info {
        max-width: 224px;
        width: 100%;
        margin-right: 14px;
    }

    .finance-info {
        min-width: 170px;
        padding-right: 8px;
        width: 12%;

    }

    .status-info {
        min-width: 170px;
        width: 14%;
    }

}

// generate-invoice-sidepannel
.generate-invoice {
    .milestone-wrapper {
        background-color: #F9F9F9;

        &.light {
            background-color: #F5FBFF;
        }


    }

    .milestone-wrapper {
        .border-right {
            border-right: 1px solid $border-line;
        }
    }

    .billing {
        padding: 0 8px;
        border-radius: 4px;

        &.unbilled {
            background-color: #FF88001A;
        }

        &.partially {
            background-color: #3370FF1A;
        }

        &.paid {
            background-color: #37FF331A;

        }
    }



    .account-detail-table {
        .hours-detail {
            border: 1px solid $border-line;
            border-radius: 4px;
            padding: 9px 16px;

        }
    }


    .search-input {
        .mat-mdc-text-field-wrapper {
            width: 386px;
            margin-right: 8px;
        }
    }




    .account-detail-table {
        &.invoice-detail {
            height: calc(100vh - 314px);
        }

        max-height: calc(100vh - 230px);
        overflow: auto;
    }

    .mat-mdc-paginator {
        position: fixed;
        bottom: 0;
        right: 0;

    }
}


.invoice-module-wrapper {
    .download-wrapper {
        border-radius: 4px;
        width: 22px;
        height: 22px;
        padding: 8px;
        border: 1px solid #30A4DC;
        background-color: #FFF;
    }

    .expense-button {
        padding: 8px 14px;
        border-radius: 4px;
        background-color: #FFF;
        border: 1px solid #30A4DC;

    }

    .mat-mdc-select-value {
        color: color("skyblue", "base");
    }

    .input-wrapper {
        .invoice-dropdown {
            .mat-mdc-text-field-wrapper {
                width: 174px;
            }

            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
                border-color: color("skyblue", "base");

            }

            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
                border-color: color("skyblue", "base");

            }

            .mat-mdc-select-value-text {
                color: color("skyblue", "base");
                font-size: 16px;
            }

            .mat-mdc-select-placeholder {
                color: color("skyblue", "base") !important;
                font-size: 16px;

            }

            .mat-mdc-select .mat-mdc-select-arrow {
                border-color: color("skyblue", "base");
            }

        }
    }
}


.invoice-activity-log {
    .mat-drawer.mat-drawer-end {
        width: 759px;
    }

    .custom-action-table {
        .name-info {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 164px;
        }


        .date-col {
            min-width: 72px;
        }

        .name-col {
            min-width: 164px;
        }

        .account-col {
            min-width: 200px;
        }

        margin-top: 0;

        .mat-mdc-row {
            height: 38px;
        }

        .table-avatar {
            &.profile {
                width: 18px;
                height: 18px;
                min-width: 18px;
            }

        }

        .mdc-data-table__row:last-child .mat-mdc-cell {
            border-bottom: none !important;
        }
    }

}

.account-detail-table {
    &.listing-border {
        height: calc(100vh - 237px);
        overflow: auto;
    }
}

.account-detail-module {
    .edit-button {
        display: none !important;
    }

    .button-wrapper {
        position: absolute;
        right: 25px;

    }

    .finance-info {
        p {
            word-wrap: break-word;
        }
    }
}


.detail-listing {
    mat-form-field {
        &.hours-col-field {
            min-width: 103px;
            width: 103px;

        }

    }

}


.resource-listing {
    mat-form-field {
        &.calender-field {
            width: 100%;
        }

    }
}

.no-resources{
    height: calc(100vh - 315px);
}

.description{
    ol{
        margin-bottom: 0;
    }
}