.sign-in {
    overflow: hidden;
    position: relative;
    height: 100%;
    min-height: calc(100vh - 58px);

    .bottom-text {
        transform: translateX(-58%);
        bottom: 30px;
    }

    .left-image,
    .right-image {
        height: auto;
        position: absolute;
        top: 0;
    }

    .left-image {
        top: -161px;
        left: -15%;
        top: -161px;
    }

    .right-image {
        right: 0;
    }

    .signin-wrapper {
        margin-top: 84px;
        margin-bottom: 70px;
        border-radius: 12px;
        max-width: 452px;
        width: 100%;
        border: 1px solid #C5D4DB;
        background: #FFF;
        box-shadow: 0px 4px 44.5px 0px rgba(0, 0, 0, 0.05);

    }

    .reset-password {
        max-width: 616px;

    }
}

// this is for reset password