// Index
// 1. Flexbox
// 2. Width Height
// 3. Text styles
// 4. Box Shadow
// 5. Scrollbar
// 6. Global chart
// 7. Loops
// 8. Snackbar
// 9. Cursor
// 10. bg colors
// 11. Position
// 12. Utility

body {
  box-sizing: border-box;
  margin: 0;
}

.bg-transparent {
  background-color: transparent;
}

.border-none {
  border: none;
}

.border-bottom-unset {
  border-bottom: 0 !important;
}

ol {
  padding: 0;
}

// Flexbox
.d-coloumn {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-end {
  align-items: end !important;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;
}

// Width Height
.h-m {
  height: 91%;
}

.sidebar-width {
  width: 285px;
  min-width: 285px;
}

.w-100 {
  width: 100%;
}

.w-unset {
  width: unset;
}

// v
.w-unset {
  width: unset !important;
}

.w-50 {
  width: 50%;
}

.h-full {
  height: 100%;
}

.h-18 {
  height: 18px;
}

.h-unset {
  height: unset !important;
}

.h-auto {
  height: auto;
}

.w-md {
  width: 464px;
}

// margin
.m-b {
  margin-bottom: 0 !important;
}

// Text styles
.text-nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end !important;
}

// Scrollbar

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e6dede;
  border-radius: 21px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d4d4d4;
}

//Loops

// SPACING// Margin Loop
@for $i from 0 through 18 {
  .mt-#{$i} {
    margin-top: $i * $spacing !important;
  }

  .mr-#{$i} {
    margin-right: $i * $spacing !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * $spacing !important;
  }

  .ml-#{$i} {
    margin-left: $i * $spacing !important;
  }

  .my-#{$i} {
    margin-top: $i * $spacing !important;
    margin-bottom: $i * $spacing !important;
  }

  .mx-#{$i} {
    margin-right: $i * $spacing !important;
    margin-left: $i * $spacing !important;
  }

  .ml-n-#{$i} {
    margin-left: -$i * $spacing !important;
  }

  .m-#{$i} {
    margin: $i * $spacing !important;
  }
}

// Padding Loop
@for $i from 0 through 16 {
  .p-#{$i} {
    padding: $i * $spacing !important;
  }

  .pt-#{$i} {
    padding-top: $i * $spacing !important;
  }

  .pr-#{$i} {
    padding-right: $i * $spacing !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * $spacing !important;
  }

  .pl-#{$i} {
    padding-left: $i * $spacing !important;
  }

  .py-#{$i} {
    padding-top: $i * $spacing !important;
    padding-bottom: $i * $spacing !important;
  }

  .px-#{$i} {
    padding-right: $i * $spacing !important;
    padding-left: $i * $spacing !important;
  }
}

//line height loop
@for $i from 0 through 12 {
  .lh-#{$i} {
    line-height: $i * $spacing !important;
  }
}

//Border Radius

@for $i from 0 through 12 {
  .border-radius-#{$i} {
    border-radius: $i * $spacing !important;
  }
}

// 3. Position
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.inherit {
  position: inherit;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.unset {
  position: unset !important;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;
}

.opacity-0 {
  opacity: 0;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

// Cursor
.pointer {
  cursor: pointer;
}

// bg colors

.primary-light-bg {
  background-color: color("skyblue", "light");
}

.primary-light-text {
  color: color("skyblue", "base"); //#30A4DC
}

.primary-bg {
  background-color: color("primary", "main");
}

.secondary-bg {
  background-color: color("skyblue", "darken");
}

.secondary-light-bg {
  background-color: color("skyblue", "light-1");
}

.tertiary-bg {
  background-color: color("orange", "base");
}

.tertiary-light-bg {
  background-color: color("orange", "lighten-1");
}

.text-bg {
  background-color: color("black", "base");
}

.text {
  color: color("black", "base");
}

.text-light-bg {
  background-color: color("black", "light");
}

.grey-text {
  color: color("black", "lighten-1"); //#414855
}

.black-text {
  color: color("shades", black); //000
}

.grey-text-bg {
  background-color: color("black", "lighten-1");
}

.grey-light-bg {
  background-color: color("black", "lighten-2");
}

.border-bg {
  background-color: color("lightblue", "lighten-8");
}

.border-light {
  background-color: color("lightblue", "lighten-5");
}

.body-bg {
  background-color: color("lightblue", "lighten-6");
}

.error-bg {
  background-color: color("orange", "darken");
}

.error-text {
  color: color("orange", "darken");
}

.border-light-bg {
  background-color: color("orange", "lighten-2");
}

.warning-bg {
  background-color: color("orange", "lighten");
}

.warning-text {
  color: color("orange", "lighten");
}

.warning-light-bg {
  background-color: color("orange", "lighten-3");
}

.Success-bg {
  background-color: color("green", "light-1");
}

.success-text {
  color: color("green", "base"); //#34C724
}

.success-light-bg {
  background-color: color("green", "light-1");
}

.info-bg {
  background-color: color("blue", "base");
}

.info-text {
  color: color("blue", "base");
}

.info-light-bg {
  background-color: color("blue", "light");
}

.text-black {
  color: #000000;
}

.bg-white {
  background-color: #fff;
}

.warning {
  color: #f54a45;
}

.grey-bg {
  background-color: #f5fbff;
}

.error-lighten {
  background-color: #f54a451f;
}

// Other CSS----------------------------------------
.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

//disable docs controls
.docblock-argstable {
  display: none !important;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

// v
.z-1 {
  z-index: 1;
}

.divider {
  border-bottom: 2px solid $border-bg;
  width: 100%;
}

.border-line {
  border-bottom: 1px solid $border-line;
}

.border-top {
  border-top: 1px solid $border-line;
}

.text-end {
  text-align: end;
}

.accounts-detail {
  background-color: #fff;
  border-radius: 8px;
  height: calc(100vh - 151px);
  overflow: scroll;

  .side-pannel-info {
    height: calc(100vh - 151px);
  }
}

.account-detail-tab {
  background: var(--White, #fff);
  width: calc(100% - 285px);

  &.management {
    width: unset;
  }

  .mat-mdc-tab-group {
    border-radius: 8px;
  }

  .mdc-tab {
    min-width: 0 !important;
    height: 38px !important;
    padding: 7px 24px 12px 24px;
    align-items: center;
    flex-grow: 0 !important;

    .mdc-tab__text-label {
      font-size: 13px;
      font-weight: 400;
      color: #414855;
      // color: #1D1D1D;
    }

    .mdc-tab__text-label:last-child {
      color: #1d1d1d;
    }
  }
}

.table-chip {
  border-radius: 4px;
}

// 12. Utility
.dropdown-footer {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1;
}

.font-italic {
  font-style: italic;
}

.overflow-auto {
  overflow: auto;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: clip;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.hidden {
  overflow: hidden;
}

.list-disc {
  list-style: disc;
}

.ls-normal {
  letter-spacing: normal !important;
}

.m-auto {
  margin: auto;
}

.bg-transparent {
  background-color: transparent;
}

.border-none {
  border: none;
}

.color-white {
  color: #fff;
}

.avatar-smX {
  height: 17px;
  width: 17px;
  min-width: 17px;
  min-height: 17px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.avatar-sm {
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.avatar-lg {
  height: 98px;
  width: 98px;
  min-width: 98px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.avatar-xl {
  height: 120px;
  width: 120px;
  min-width: 120px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.grey-dot {
  border-radius: 50%;
  width: 7px;
  height: 7px;
}

.w-fit {
  width: fit-content;
}

.tab-border-bottom {
  border-bottom: 1px solid $border-line;
}

.delete-circle {
  height: 88px;
  width: 88px;
  background: rgb(255 136 0 / 20%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cdk-global-overlay-wrapper {
  overflow-y: scroll;
}

.mat-mdc-dialog-container {
  padding: 50px 0;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}


.table-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// this is for text-editor
.NgxEditor {
  ul {
    li {
      list-style: disc;
      margin-left: 20px;
    }
  }

  min-height: 379px;
}

.NgxEditor__MenuBar {
  padding: 8px;
  border: 1px solid;
  border-color: color("lightblue", "lighten-8");
}

// this is for filter box 
.select-box {
  .mat-mdc-text-field-wrapper {
    background-color: #fff;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    background-color: unset;

  }
}

.status-box {
  &.project-box {
    .mat-mdc-text-field-wrapper {
      background-color: #fff;
    }

    .mdc-text-field--outlined .mdc-notched-outline {
      background-color: unset;

    }
  }
}


.search-input {
  .mat-mdc-text-field-wrapper {
    width: 300px;
  }
}


.mat-input-wrapper {
  .mat-mdc-text-field-wrapper {
    width: calc(100% - 8px);

  }
}

.example-accordion-item-header,
.example-accordion-item-body {
  max-height: 166px;
  overflow: scroll;

}

.word-break {
  word-break: break-word;
}



.projectfield {
  &.mat-mdc-select-panel {
    max-height: 249px !important;
  }
}

.customer-logo {
  width: 55px;
  height: 55px;

  img {
    width: 100%;
    height: 100%;

  }
}

.invoice-term-details {
  border-radius: 4px;
}

.border-line-invoice {
  border-bottom: 1px solid $border-line;
}

.info-hide{
  display: none !important;
}

.edit-button {
  height: 28px !important;
  width: 28px !important;
  margin-left: 18px;
  padding: 2px;
  text-align: center;
  border: 1px solid $primary-color;
  border-radius: 6px;
}


.account-detail-tab{
  &.management{
    .mat-mdc-tab-labels{
      border-bottom: 1px solid $border-line;

    }

  }

}

.active-circle{
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #C30102;
}

.reset-button-wrapper{
  button{
    padding-left: 0 !important;
  }
}