@use "sass:map";
@use "@angular/material" as mat;
@include mat.core();
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$light-focused: rgba(white, 0.12);
$light-dividers: rgba(white, 0.12);
$light-disabled-text: rgba(white, 0.5);
$light-secondary-text: rgba(white, 0.7);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
// Define a light theme
$grey-palette: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);
$pink-palette: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$my-primary: (
  50 : #e6f4fb,
  100 : #c1e4f5,
  200 : #98d2ee,
  300 : #6ebfe7,
  400 : #4fb2e1,
  500 : #30a4dc,
  600 : #2b9cd8,
  700 : #2492d3,
  800 : #1e89ce,
  900 : #1378c5,
  A100 : #f5faff,
  A200 : #c2e2ff,
  A400 : #8fcbff,
  A700 : #75bfff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #f9f8f8,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// $my-primary: mat.define-palette($my-primary, 500, 200, 900);
// $my-accent: mat.define-palette($my-accent, 500, 200, 900);


$my-accent: (
  50 : #e2f0f6,
  100 : #b7dae9,
  200 : #C3E0ED,
  300 : #58aacd,
  400 : #3497c2,
  500 : #1085b7,
  600 : #0e7db0,
  700 : #0c72a7,
  800 : #09689f,
  900 : #055590,
  A100 : #bddfff,
  A200 : #8ac7ff,
  A400 : #57afff,
  A700 : #3da2ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my-warn: (
  50 : #fff1e0,
  100 : #ffdbb3,
  200 : #ffc480,
  300 : #ffac4d,
  400 : #ff9a26,
  500 : #ff8800,
  600 : #ff8000,
  700 : #ff7500,
  800 : #ff6b00,
  900 : #ff5800,
  A100 : #ffffff,
  A200 : #fff6f2,
  A400 : #ffd1bf,
  A700 : #ffbfa6,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$my-primary: mat.define-palette($my-primary, 400, 200, 900);
$my-accent: mat.define-palette($my-accent, 500, 200, 900);
$my-warn: mat.define-palette($my-warn, 500, 200, 900);

// $custom-dark-theme-background-palette: (
//   status-bar: black,
//   app-bar: map.get($grey-palette, 800),
//   background: #303030,
//   hover: rgba(white, 0.04),
//   card: map.get($grey-palette, 800),
//   dialog: map.get($grey-palette, 800),
//   disabled-button: rgba(white, 0.12),
//   raised-button: map.get($grey-palette, 800),
//   focused-button: $light-focused,
//   selected-button: map.get($grey-palette, 900),
//   selected-disabled-button: map.get($grey-palette, 800),
//   disabled-button-toggle: black,
//   unselected-chip: map.get($grey-palette, 700),
//   disabled-list-option: rgba(white, 0.12),
//   tooltip: map.get($grey-palette, 700),
// );
// $custom-dark-theme-foreground-palette: (
//   base: white,
//   divider: $light-dividers,
//   dividers: $light-dividers,
//   disabled: $light-disabled-text,
//   disabled-button: rgba(white, 0.3),
//   disabled-text: $light-disabled-text,
//   elevation: black,
//   hint-text: $light-disabled-text,
//   secondary-text: $light-secondary-text,
//   icon: white,
//   icons: white,
//   text: white,
//   slider-min: white,
//   slider-off: rgba(white, 0.3),
//   slider-off-active: rgba(white, 0.3),
// );

// @function custom-dark-theme($primary, $accent) {
//   @return (primary: $primary,
//     accent: $accent,
//     warn: $primary,
//     is-dark: true,
//     foreground: $custom-dark-theme-foreground-palette,
//     background: $custom-dark-theme-background-palette );
// }

// Foreground palette for light themes.
$custom-light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);


//typography
// $my-custom-level: mat.define-typography-level(
//   $font-family: Roboto,
//   $font-weight: 400,
//   $font-size: 1rem,
//   $line-height: 1,
//   $letter-spacing: normal,
// );

// $my-custom-typography-config: mat.define-typography-config(
//   $headline-5: mat.define-typography-level(24px, 20px, 500),
//   /* h1, headline .mat-headline-5*/ $subtitle-1:
//     mat.define-typography-level(18px, 21px, 400, 0.1px),
//   /* h3,.mat-subtitle-1*/ $subtitle-2:
//     mat.define-typography-level(16px, 20px, 400, 0.1px),
//   /* h4, sub-heading .mat-subtitle-2*/ $body-1:
//     mat.define-typography-level(14px, 20px, 400),
//   /* table-content .mat-body-1*/ $caption:
//     mat.define-typography-level(12px, 20px, 500, 0.1px),
//   /* subtext .mat-caption*/
// );

// $my-typography: $my-custom-typography-config;


$light-primary: mat.define-palette($my-primary);
$light-accent: mat.define-palette($my-accent);
$light-warn: mat.define-palette($my-warn);
$light-theme: mat.define-light-theme((color: (primary: $light-primary,
        accent: $light-accent,
        warn: $light-warn ),
    ));

// Define a dark theme
// $dark-primary: mat.define-palette(mat.$pink-palette, 400, 200, 900);
// $dark-accent: mat.define-palette(mat.$purple-palette, 900);
// $custom-dark-theme: custom-dark-theme($dark-primary, $dark-accent);

@include mat.all-component-themes($light-theme);

// [data-app-theme="dark"],
// .dark-mode {

//   .mat-app-background,
//   .outer-layout {
//     background: #303030;
//   }

//   @include mat.all-component-colors($custom-dark-theme);
// }
// style for dark theme

// [data-app-theme="dark"],
// .dark-mode {
//   textarea {
//     color: #ffffff;
//   }

//   // for svg icon having alt="..-icon"
//   img[alt*="icon"] {
//     filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(48deg) brightness(105%) contrast(105%);
//   }
// }

@mixin mdc-button-text-label-color($color) {
  .mdc-button__label {
    color: $color; // Set the color for the text label
  }
}

.my-custom-button {
  @include mdc-button-text-label-color(#ffffff);
}

// .mdc-button__label {
//   color: #ffffff; // Set the color for the text label
// }

.docblock-argstable {
  display: block !important;
}


.mat-typography {
  .mat-headline-2 {
    margin: 0 !important;

  }
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}