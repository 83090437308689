// Google Color Palette defined: http://www.google.com/design/spec/style/color.html
// this is for the sams project
$skyblue: (
    "base":#30A4DC,
    "darken":#1085B7,
    "light":rgb(48 164 220 / 25%),
    "light-1":rgba(16, 133, 183, 0.25),
);
$orange: (
    "base":#EF5424,
    "darken":#F54A45,
    "lighten":#FF8800,
    "lighten-1":rgba(239, 84, 36, 0.25),
    "lighten-2":rgba(245, 74, 69, 0.25),
    "lighten-3":rgba(255, 136, 0, 0.25),

);
$lightblue: (
    "lighten-5":rgba(197, 212, 219, 0.75),
    "lighten-6":#F5FBFF,
    "lighten-7":#E0EAFF,
    "lighten-8":#C5D4DB,

);
$grey: (
    "base":#666D79,
    "light":rgba(102, 109, 121, 0.25),
    "lighten-2":rgba(197, 212, 219, 0.25),
);
$black: (
    "base":#1D1D1D,
    "light":rgba(29, 29, 29, 0.25),
    "lighten-1":#414855,
    "lighten-2":rgba(65, 72, 85, 0.25),
);
$green: (
    "base":#34C724,
    "light-1":rgba(52, 199, 36, 0.25),
);
$blue: (
    "base":#3370FF,
    "light": rgba(51, 112, 255, 0.25),
);

// ending

$red: (
    "base": #ff0404
);

$red: (
    "base": #ED5267,
    "darken": #C42339,
    "lighten": #E44258,
    "lighten-2": #F9DADE
);
$shades: (
    "black": #000000,
    "white": #ffffff,
    "transparent": transparent,
);

//Project Color pallete
$primary: (
    "main":#30A4DC,
    "light": #8082FF,
    "dark": #6062E8,
    "hover":#5456CC,
    "light2": #F0ECFF,
    "light3": #696CFF,
);
$success: (
    "main":#4AB778
);
$text: (
    "primary":rgba(50, 71, 92, 0.87),
    "secondary": rgba(50, 71, 92, 0.6),
    "disabled": rgba(50, 71, 92, 0.38),
    "lighten": #748291,
    "red": #E97272
);
$error: (
    "main":#E34F4F,
    "light": #FF5B3F,
    "dark": #E8381A
);
// empty-state Classes
$purple: (
    "lighten":#696CFF,
    "darken": #444791
);

//not is use
$sky-blue: (
    "base": #009E8E,
    "lighten": #ABFBF3,
);
$empty-red: (
    "base": #FF6F41,
    "lighten": #FFDACE,
);
$empty-pink: (
    "base": #ED5267,
    "lighten": #FFDCE1,
);
$colors: (
    "red": $red,
    "blue": $blue,
    "green": $green,
    "purple": $purple,
    "orange": $orange,
    "black": $black,
    "grey": $grey,
    "lightblue":$lightblue,
    "shades": $shades,
    "skyblue": $skyblue,
    "empty-red": $empty-red,
    "empty-pink": $empty-pink,
    "primary": $primary,
    "text": $text,
    "error": $error,
    "success": $success) !default;
$lighten-blue: #3a4580;
$red-lighten: #E44258;
$red-lighten1: #F9DADE;
$yellow-lighten: #FFF5E6;
$yellow-lighten2: #FBF3E0;
$input-grey: #909194;
$white-2: #f2f2f2;
$white-3: #ffffff0d;

//Common Classes
.bg-white {
    background-color: #fff;
}

.bg-success {
    background: rgba(52, 199, 36, 0.10
    );
}

.bg-warning {
    background: rgba(255, 136, 0, 0.10);
}

.bg-info {
    background: rgba(51, 112, 255, 0.10);
}

.text-black {
    color: var(--Text-Color, #1D1D1D);
}

.text-error {
    color: #E34F4F !important;
}

.text-info {
    color: var(--Info, #3370FF);
}

.text-main {
    color: #603ADE;
}

.text-warning {
    color: #FF7F37 !important;
}

.text-primary {
    color: rgba(50, 71, 92, 0.87);
}

.text-secondary {
    color: rgba(50, 71, 92, 0.6);
}

.text-disabled {
    color: rgba(50, 71, 92, 0.38);
}

.text-success {
    color: #34C724 !important;
}

.text-grey1 {
    color: #5B5B5B;
}

.text-grey3 {
    color: #32475C;
}

.text-grey2 {
    color: #7C7B7B;
}

.text-grey4 {
    color: #414855;
}

.text-purple1 {
    color: #696CFF !important;
}


// Color Classes
@each $color_name,
$color in $colors {

    @each $color_type,
    $color_value in $color {
        @if $color_type=="base" {
            .#{$color_name} {
                background-color: $color_value !important;
            }           
        }

        @else if $color_name !="shades" {
            .#{$color_name}.#{$color_type} {
                background-color: $color_value !important;
            }

        }
    }
}


@function color($color, $type) {
    @if map-has-key($colors, $color) {
        $curr_color: map-get($colors, $color);

        @if map-has-key($curr_color, $type) {
            @return map-get($curr_color, $type);
        }
    }

    @return null;
}