.customer-listing {
    border: 1px solid $border-line;
    border-radius: 8px;


    .account-detail-table {
        .name {
            min-width: 371px;
        }

        .account {
            min-width: 220px;

        }

        .cost {
            min-width: 240px;

        }

        .date {
            min-width: 184px;
        }

        .opening {
            min-width: 163px;

        }

        .action {
            min-width: 70px;

        }

    }

}

// modal
.modal-wrapper {
    .delete-icon-wrapper {
        .material-symbols-outlined {
            font-size: 48px;

        }

    }

}



// team-management
.listing-wrapper {
    .dashboard-wrapper {
        .resource-col {
            max-width: 122px;  
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;      

        }
 

        .name {
            min-width: 200px;
        }

        .project-role-col{
            max-width: 200px;

        }

        .role {
            min-width: 200px;
        }

        .date {
            min-width: 200px;
        }

        .action {
            min-width: 73px;
        }



        .search-input {
            max-width: 300px;
            width: 100%;
        }

        .input-wrapper {
            .status-box {
                max-width: 174px;
            }
        }
    }
}


.cdk-overlay-pane .mat-mdc-select-panel {
    &.pannel-dropdown {
        height: 100%;
        max-height: 196px;

    }
}


// customer-open-position
.customer-open-position {
    .role-type {
        min-width: 200px;
    }
    .cost-type{
        min-width: 220px;
    }

}

.custom-management-wrapper{
    .management-header-wrapper{
        position: sticky;
        top: 0px;
        background: #f5fbff;
        z-index: 999;
    }
}