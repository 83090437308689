.account-wrapper {
  .header-wrapper {
    height: 68px;

    app-stepper {
      align-self: flex-end;
    }
  }

  .brand-info {
    background-color: $border-bg;
    border-radius: 4px;

    .time-info {
      .material-icons {
        font-size: 18px;
      }
    }
  }

  .account-detail {
    max-width: 947px;

    .upload-photo {
      width: 193px;
      padding: 30px 21px 15px;
      border-radius: 4px;
      border: 1px dashed #c5d4db;
      background: #fff;

      .file-input {
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
      }
    }

    .upload-photo:hover {
      border-color: $primary-color;

      mat-icon {
        color: $primary-color;
      }

      img {
        filter: invert(38%) sepia(81%) saturate(661%) hue-rotate(156deg) brightness(95%) contrast(90%);
      }

      .logo-info {
        color: $primary-color;
      }
    }
  }
}

// expense detail
.expense-wrapper {
  .expense-info {
    background-color: $border-bg;
  }
}

// stepper module
.account-header {
  .angular-stepper {
    .mat-horizontal-stepper-header-container .mat-step-header:last-child .mat-ligature-font {
      display: none;
    }
  }

  align-items: flex-start;

  // this  is for stepper left logo and side button
  .logo-img {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
    border-bottom: 1px solid $border-line;
    height: 68px;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .mat-horizontal-stepper-header-container {
    height: 68px;
    border-bottom: 1px solid $border-line;
    align-items: end;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
  }

  .mat-horizontal-content-container {
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-button-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
    border-bottom: 1px solid $border-line;
    height: 68px;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }

  // ending
}

// empty account wrapper
.bgform-img {
  height: 281px;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

// account-detail
.account-detail-tab {
  &.management {
    .mat-mdc-tab-body-content {
      height: unset !important;
    }
  }

  .mat-mdc-tab-labels {
    border-bottom: none;
  }


  .search-block {
    width: 300px;
  }


  .mat-mdc-tab-body-content {
    height: calc(100vh - 215px) !important;

  }

  .project-management.account-detail-table.table-container {
    height: calc(100vh - 352px) !important;
  }


  .account-detail-table.table-container {
    height: calc(100vh - 352px);
    overflow: scroll !important;
  }

  .account-detail-table {
    .cost-center-info {
      min-width: 288px;
    }

    .cost-center-action {
      min-width: 73px;
    }

    .core-team {
      min-width: 249px;
    }

    .project-info {
      min-width: 173px;
    }
  }


  .account-detail-table.table-container {
    height: calc(100vh - 352px);
    overflow-y: hidden;
  }

  .account-detail-table {
    .cost-center-info {
      min-width: 288px;
    }

    .mat-mdc-tab-labels {
      border-bottom: none;

      .cost-center-action {
        min-width: 73px;
      }

      .core-team {
        min-width: 249px;
      }

      .project-info {
        min-width: 173px;
      }
    }

    .cost-info {
      padding: 78px;
    }

    .search-block {
      width: 100%;
      max-width: 300px;
    }

    .activity-listing {
      .activity-info {
        min-width: 164px;
      }
    }
  }
}


.timeZone-wrapper{
  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-right: 12px;
  }

}



