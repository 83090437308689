.m4{
  width: 25%;
  float: left;
}
.m8{
  width: 75%;
  float: left;
}
.container {
  // margin: 0 auto;
   padding: 0 1.5rem;
  // width: calc(100% - 4rem);
}

@media (max-width:#{$medium-screen-up}){
  .login .login-content {
    padding: 4rem 6rem 3rem;
  }
}
@media (max-width:#{$small-screen}){
  .mat-typography input, .mat-typography .mat-select {
    max-width: 450px;
    width: 100%;
  }
  .login {
     height: 100%; 
    padding: 4rem 0;
    display: block;
    .login-content {
      padding: 3rem 1.5rem 2rem;
      margin: 3rem 1.5rem 0;
      .mat-checkbox-layout .mat-checkbox-label, .forgot-password {
        font-size: 1.4rem;
      }
    }
  }
}
@media #{$medium-and-up} {
  /* .container {
    width: 85%;
  } */

}

@media #{$large-and-up} {
  /* .container {
    width: 70%;
  } */
}

.col .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
 
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }

  &.no-pad-bot {
    padding-bottom: 0;
  }

  &.no-pad-top {
    padding-top: 0;
  }
}


// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}

@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }

  &.pull-#{$size}#{$i} {
    right: $perc;
  }

  &.push-#{$size}#{$i} {
    left: $perc;
  }
}


.row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  @include flexbox;
  @include flex-wrap(wrap);

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      &.s#{$i} {
        width: $perc;

      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("s", $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.m#{$i} {
          width: $perc;

        }

        $i: $i+1
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("m", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.l#{$i} {
          width: $perc;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("l", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.xl#{$i} {
          width: $perc;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }

    @media only screen and (min-width : 1800px) {

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.xxl#{$i} {
          width: $perc;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}

