// 1. Mat-chip
// 2. Tables
// 3. list checkbox
// 4. accordion custom
// 5. ul with green mark
// 6. chip with success-bg
// 7. coupons dialog
// 8. mat-paginator
// 9. input border color
// 10. radio button
// 11.stepper
// 12 . textarea
// 13 .snack-bar
// 14 . mat-tab
// 15  .switch
// 16  calender picker

// 1. mat-chip


// 2. Tables
.mat-mdc-table{
  .mat-mdc-header-row{
    position: sticky;
    top: 0;
    background:color("lightblue", "lighten-6");
    z-index: 1;
  }
}


.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 13px !important;
  color: $text-color;
}

.mat-mdc-tab-labels {
  border-bottom: 1px solid $border-line;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid var(--Border-Color, #c5d4db) !important;
  z-index: 1;
  position: relative;
}


// switch

.switch-box {
  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: 24px;
    border-radius: 24px;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icon {
    width: 0.625rem;
    height: 0.625rem;
  }

  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
    height: 20px;
    width: 20px;
  }

  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
    width: 20px;
  }

  svg.mdc-switch__icon.mdc-switch__icon--on {
    display: none;
  }

  svg.mdc-switch__icon.mdc-switch__icon--off {
    display: none;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: #fff;
  }

  .mdc-switch__shadow {
    background: #fff;
    box-shadow: none;
  }

  &.mat-mdc-slide-toggle .mdc-switch {
    width: 43px;
  }
}

// 9.input border color
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #F54A45 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: color('lightblue', 'lighten-8');
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline {

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: color('lightblue', 'lighten-8');
  }
}

mat-form-field .mat-mdc-text-field-wrapper {
  height: 40px;

  &.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control::placeholder {
  color: $grey-text !important;
  z-index: 1;
  position: relative;
  font-size: 14px;
}

.mat-mdc-select-placeholder {
  color: $grey-text !important;
  z-index: 1;
  top: -3px;
  position: relative;
  font-size: 14px;
}

.mat-mdc-select .mat-mdc-select-arrow {
  z-index: 1;
  width: 6px;
  height: 6px;
  transform: rotate(134deg);
  top: -4px;
  border-right: 2px solid $text-color;
  border-top: 2px solid $text-color;
  margin: 0 4px;

  svg {
    opacity: 0;
    display: none;
  }
}

.mat-mdc-select-value-text {
  z-index: 1;
  position: relative;
}

// ask

.mdc-text-field--outlined .mdc-notched-outline {
  background-color: #fff;
}



mat-form-field {
  &.mat-focused {
    .mat-mdc-select .mat-mdc-select-arrow {
      border-color: $primary-color;

    }

    mat-icon {
      color: $primary-color;

    }
  }

}

.mat-mdc-form-field-flex {
  height: 41px;
}

// typography

.mat-headline-2,
.mat-typography .mat-headline-2,
.mat-headline-3,
.mat-headline-4,
.mat-headline-5,
.mat-body-1,
.mat-subtitle-1,
.mat-subtitle-2,
.mat-caption,
.mat-mdc-button,
.mdc-list-item__primary-text,
.mat-mdc-header-row,
.mat-mdc-header-row,
.mat-step-text-label,
.mat-mdc-select-placeholder,
.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control::placeholder,
.mat-mdc-cell,
.mdc-button__label,
.mat-mdc-header-cell,
.mat-mdc-menu-item-text,
.mat-headline-2,
.mat-headline-4,
.mat-headline-5,
.mat-subtitle-1,
.mat-subtitle-2,
.mat-body-1,
.textXS,
.mat-mdc-paginator-range-label,
.textS,
.mat-mdc-paginator-page-size-label,
.mat-mdc-select-min-line,
.mdc-tab__text-label,
.mat-mdc-slide-toggle .mdc-label,
table ,
.mat-caption {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font: 'Poppins', sans-serif !important;
  letter-spacing: 0.055;
}



// 10 .radio button

.mdc-form-field {
  .mdc-label {
    font-size: 13px;
    color: $text-color;
  }
}

//  11. stepper
.angular-stepper {
  .mat-horizontal-stepper-wrapper {
    .mat-horizontal-content-container {
      padding: 0;
    }

    .mat-horizontal-stepper-header {
      height: 52px;
      box-sizing: border-box;
    }

    .mat-stepper-horizontal-line {
      display: none;
    }

    .mat-step-icon {
      display: none;
    }

    .mat-step-text-label {
      display: flex;
      align-items: center;
    }

    .mat-step-header {
      padding: 7px 14px;
    }

    .mat-step-header[aria-selected="true"] {
      border-bottom: 2px solid #1085B7;
      padding: 7px 14px;

      .mat-step-label.mat-step-label-active {
        color: $primary-color; //#30A4DC

        img {
          filter: invert(38%) sepia(81%) saturate(661%) hue-rotate(156deg) brightness(95%) contrast(90%);
        }
      }
    }

    .mat-step-label {
      color: $grey-text;
      font-size: 13px;
      font-weight: 500;
    }
  }
}


.table-container {
  overflow: auto;

  .mat-mdc-paginator-container {
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  .mat-mdc-paginator .mat-mdc-select-value {
    font-size: 13px;
    color: $text-color;
  }

  .mat-mdc-paginator-range-label {
    margin: 0 8px 0 8px;
  }

}

// 12 . textarea
.text-area-wrapper {
  .mat-mdc-form-field-flex {
    height: unset !important;
  }

  mat-form-field .mat-mdc-text-field-wrapper {
    height: unset;
  }
}

// 13.select-box
.mat-mdc-select-value-text {
  bottom: 3px;


}

// 14 snack-bar
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  width: 529px;
  border-radius: 0 !important;
  box-shadow: none !important;
  min-height: 40px;
  top: 20px;
  position: relative;
  padding: 0;

}

.mat-mdc-snack-bar-label {
  .mdc-snackbar__label {
    padding-left: 50px;
    padding-top: 10px;
    padding-right: 10px;
    padding-right: 0;
  }
}

mat-snack-bar-container {
  &.warning-snackbar {
    .mdc-snackbar__surface {
      background-color: $Warning-color;

    }
  }

  &.info-snackbar {
    .mdc-snackbar__surface {
      background-color: $link-color;
    }
  }

  &.error-snackbar {
    .mdc-snackbar__surface {
      background-color:
        $error-color;
    }

  }


  &.success-snackbar {
    .mdc-snackbar__surface {
      background-color: color("green", "base");
    }

  }
}

snack-bar {
  .material-symbols-outlined {
    font-size: 20px;
    color: #fff;
    display: flex;
  }
}


.required-star {
  line-height: 1;
  color: $error-color;
  font-size: 14px;
  vertical-align: middle;
}


// 15  .switch
.switch-wrapper {
  .mat-mdc-slide-toggle .mdc-label {
    margin-right: 84px;
    padding-left: 0;
    padding-right: 0;
  }
}

// 14 . mat-tab
.account-detail-tab {
  &.settings{
    .mat-mdc-tab-body-content {
      height: unset !important;
    }
  }

  &.with-scroll {
    .mat-mdc-tab-body .mat-mdc-tab-body-content {
      overflow: scroll !important;
    }
  }
}

.mdc-tab__text-label {
  letter-spacing: normal;
}


// 8. mat-paginator
.mat-mdc-paginator-page-size{
  align-items: center !important;
}

.action-table-pagination{
  .mdc-text-field--outlined .mdc-notched-outline {
      background-color: #F5FBFF;
  }
  

}




// mat-input-field
.account-field {
  &.mat-mdc-select-panel{
    max-height: 253px !important;
  }
}

.listing-border {
  &.table-border{
    .mat-mdc-table {
      border: 1px solid $border-line;

    }
  }

}

.finance-detail-wrapper {
    .country-select {
      width: 95px;
      margin-right: 8px;
      .mdc-text-field--outlined {
        padding-left: 8px;
        padding-right: 8px;
      }   

  }

}
 
// calender picker
.mat-datepicker-content .mat-calendar-body-selected {
  background-color: $primary-color!important;
  color: #fff !important;

}

.mat-calendar-body-cell-content{
  color: $text-color!important;
  font-weight: 500;
}

.mat-calendar-table-header th {
  color: #414855 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.mat-mdc-button .mdc-button__label,
 .mat-mdc-unelevated-button .mdc-button__label, 
 .mat-mdc-raised-button .mdc-button__label, 
 .mat-mdc-outlined-button .mdc-button__label {
  font-size: 16px;
  font-weight: 500;
}

.mat-mdc-checkbox-touch-target{
  height: 100% !important;
  width: 100% !important;
}