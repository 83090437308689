@mixin mdc-button-text-label-color($color) {
  .mdc-button__label {
    color: $color; 
  }
}

.my-custom-button {
  @include mdc-button-text-label-color(#ffffff);
}

.mdc-button,
.mat-mdc-button {
  font-weight: 400;



  &.mat-primary {
    &.mat-mdc-outlined-button {
      background-color: #FFFFFF;
      border-radius: 4px;
      color: $primary-color;
      border: 1px solid $primary-color;

      .mat-icon {
        color: $primary-color !important;
      }

      &:hover {
        background-color: $border-bg;
        border: 1px solid $secondary-color;
      }
    }

 

  }

  &.text {
    color: $primary-color !important;
    border-radius: 4px !important;

    .mat-icon {
      color: $primary-color !important;
    }

    &:hover {
      border-radius: 4px;
      background-color: $border-bg;
    }

  }

  &.warn {
    background-color: $error-color !important;
    color: #FFFFFF !important;

    .mat-icon {
      color: #FFFFFF !important;
    }

    &:hover {
      background-color: #E54541;
      border-radius: 4px;
    }
  }

  &.small {
    font-size: 11px;
    height: 25px;
    padding: 0 14px;


    .mat-icon {
      font-size: 20px !important;
      height: 20px !important;
      width: 20px !important;
    }
  }

  &.medium {
    font-size: 14px;
    height: 32px;
    padding: 0 14px;


    .mat-icon {
      font-size: 24px !important;
      height: 24px !important;
      width: 24px !important;
    }
  }

  &.large {
    font-size: 16px;
    padding: 0 14px;
    height: 36px;


    .mat-icon {
      font-size: 24px !important;
      height: 24px !important;
      width: 24px !important;
    }
  }

  &.xl {
    height: 40px;
    font-size: 16px;
    padding: 0 14px;

    .mat-icon {
      font-size: 24px !important;
      height: 24px !important;
      width: 24px !important;
    }

  }
}

button.small.mdc-fab.mat-mdc-fab.mat-primary.mat-mdc-button-base {
  height: 36px;
  width: 36px;
  box-shadow: none;
}

sft-button {
  min-width: fit-content;
}

.mdc-button__label {
  letter-spacing: normal;
}

.grey-text-btn {
  color: #1D1D1D !important;

  &.textXS {
    font-size: 12px !important;
  }
}

.text-blue-button {
  &.mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: #3370FF;
  }
}

// outline-button
.header-info-wrapper {

  .mat-mdc-button>.mat-icon,
  .mat-mdc-unelevated-button>.mat-icon,
  .mat-mdc-raised-button>.mat-icon,
  .mat-mdc-outlined-button>.mat-icon {
    font-size: unset;
    height: unset;
    width: unset;
  }

  .outline-button {
    height: 40px;

    .mdc-button__label {
      display: flex;

      img {
        margin-top: 2px;
        height: 16px;
      }
    }

    border:1px solid;
    border-color: color("skyblue", "base");
    width: 97px;

    span {
      color: color("skyblue", "base");
      font-size: 16px;
      font-weight: 400;

    }

    mat-icon {
      font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      font-family: "Material Symbols Outlined";
      font-weight: normal;
      font-style: normal;
      font-size: unset;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
    }
  }
}

.menu-content {
  .mat-mdc-menu-content {
    width: 281px;
  }
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  border-radius: 8px;
}