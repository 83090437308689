// Index
//1. Font size variants
// text properties

a {
  text-decoration: none;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3,
.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2,
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p ,
.mat-typography h4 {
  margin: 0;
}

html {
  line-height: 1.5;
  font-size: 10px;
  font-family: $font-regular;
  font-weight: normal;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

.medium {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}


//Font size variants
.textL {
  font-size: 20px;
  line-height: 28px;
}

.textS {
  font-size: 13px !important;
}

.textXS {
  font-size: 12px !important;
}

.textSM {
  font-size: 10px;
}

.center-selection-output-area ul li a.active {
  background: #1aafd0;
  color: #fff;
}

p.para {
  font-size: 16px;
  line-height: 24px;
  color: color("grey", "darken");
  margin: 0;
}

small {
  font-size: 14px;
  line-height: 24px;
  color: color("grey", "darken");
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

// text properties
// text color
.link-text {
  color: #30A4DC;
}

.grey-lg {
  color: #5E7080;
}

.text-capital {
  text-transform: capitalize;
}

.m-auto {
  margin: auto;
}

.upload-grey-text {
  color: #92929D;
}

.text-black {
  color: #000;
}

.bg-white {
  background-color: #fff !important;
}

.sec-text-color{
  color:  #1D1D1D;
}
.closed-text-color {
  background-color: #f5f5f5;
}

.textl{
  font-size: 18px;
}