.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.avatar-md {
  width: 55px;
  height: 55px;
  border: 1px solid #F5FBFF;
  border-radius: 50%;
  overflow: hidden;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}


.table-avatar {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.mat-toolbar {
  background: white !important;
}

// ----------yatin
.sb-show-main.sb-main-padded {
  padding: 0rem !important;
}

.profile-custom-menu {
  min-width: 300px !important;
  padding: 1rem;

  .profile_menu {
    .mat-mdc-menu-item-text {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
}

.add-dashboard-shortcut {
  min-width: 200px !important;
  padding: 0rem 1rem;
}

.side-nav-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-items: center;
  padding-top: 16px;

  .sidebar-menu {
    display: flex;
    align-items: center;
    gap: 8px;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
    }
  }



  .active-list {
    background: linear-gradient(90deg,
        #daefff 39.06%,
        rgba(245, 251, 255, 0.78) 100%);
    position: relative;

    .sidebar-menu {
      color: color("skyblue", "darken");
    }

    .mdc-list-item__content {
      &:before {
        content: "";
        height: 24px;
        width: 3px;
        background-color: color("skyblue", "darken");
        position: absolute;
        left: 0;
      }
    }
  }
}



.mat-drawer-container.mat-sidenav-container {
  .collapse-side-nav-logo {
    display: none;
  }

  &.side-nav-open {
    .side-nav-logo {
      display: none;
    }
  }

  &.side-nav-open {
    .collapse-side-nav-logo {
      display: block;
    }
  }
}

.mat-drawer-container {
  height: 100vh;
}

.profile-text-color {
  color: #002640;
}

.custom-action-table.mat-mdc-table {
  box-shadow: none;
  margin-top: 1rem;
  background-color: transparent !important;

  .action-table-header-data {
    color: var(--Text-Color, #1d1d1d);
    text-align: center;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    height: 40px;

    .mat-mdc-header-cell {
      border-bottom: none;
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 12px 0 12px;
    }
  }

  &.shadow-none tr {
    box-shadow: none;
  }

  .action-table-body-data {
    border-radius: 2px;
    border-bottom: 1px solid var(--Border-Color, #c5d4db);
    background: var(--White, #fff);
    // box-shadow: 0px 2px 2px 0px #d6e8f0;
    color: var(--Text-Color, #1d1d1d);
    text-align: center;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 12px 0 12px;
    }
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: calc(100vh - 263px);
}

.action-table-pagination {
  &.management {
    width: calc(100% - 273px);
  }

  &.invoicing {
    width: calc(100% - 278px);
  }

  &.dashboard-pagination {
    width: calc(100% - 272px);
  }

  &.expense {
    width: calc(100% - 893px);

    &.activity {
      width: calc(100% - 320px);
    }
  }

  width: calc(100% - 262px);
  height: 48px;
  bottom: 24px;
  border-radius: 4px;

  .mat-mdc-paginator-range-label {
    font-size: 13px;
    font-weight: 500;
  }

  .mat-mdc-paginator-page-size-label {
    font-size: 13px;
    font-weight: 500;
    color: $text-color;
  }

  .mat-mdc-paginator-outer-container {
    display: flex;
    align-items: center;
    height: 48px;
  }

  mat-form-field .mat-mdc-text-field-wrapper {
    height: 38px !important;
    background: var(--Background, #f5fbff);
  }
}



.search-input {
  height: 40px;
  margin-right: 8px;
  background: var(--White, #fff);
  &.user{
    margin-right: 0;
  }
}

.account-detail-table {
  .account-detail-header {
    color: var(--Text-Color, #1d1d1d);
    text-align: center;
    font-family: Poppins;
    font-size: 13px !important;
    font-style: normal;
    background-color: #f5fbff;
    font-weight: 400 !important;
    height: 40px;

    .mat-mdc-header-cell {
      border-bottom: none;
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 12px 0 12px;
    }
  }

  .account-detail-body {
    border-radius: 2px;
    border-bottom: 1px solid var(--Border-Color, #c5d4db);
    background: var(--White, #fff);
    // box-shadow: 0px 2px 2px 0px #d6e8f0;
    color: var(--Text-Color, #1d1d1d);
    text-align: center;
    font-family: Poppins;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 12px 0 12px;
    }
  }
}

.search-block {
  .search-input {
    height: 34px;

    mat-form-field .mat-mdc-text-field-wrapper {
      height: 34px;
    }

    .mat-mdc-form-field-flex {
      height: 34px;
    }

    .mat-mdc-form-field-infix {
      min-height: 34px;
    }
  }

  mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

// this is for mat-tooltip
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #3370FF;
}

.tooltip .tooltiptext {
  box-sizing: border-box;
  visibility: hidden;
  width: 309px;
  background-color: #3370FF;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  top: 21px;
  right: 35px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #3370FF;
}

.tooltip .tooltiptext {
  visibility: visible;
}

.tooltip-button {
  padding: 4px 14px 4px 14px;
  background-color: #FFFFFF;
  height: 32px;
  border: 1px;
  border-radius: 4px;

}

// this is for custom input
.optional-cost-input {
  .mat-mdc-form-field {
    width: 271px;
  }
}

// this is for pagination
mat-sidenav-container {
  .management {
    .mat-mdc-paginator-outer-container {
      margin-top: 0;
    }
  }

  .customer-position {
    .mat-mdc-paginator-outer-container {
      margin-top: 0;
    }

  }

  .mat-mdc-paginator {
    z-index: 1;

    &.cost-center {
      width: calc(100% - 278px);
    }

    &.project-listing {
      width: calc(100% - 283px);
    }

    &.resource-listing {
      width: calc(100% - 278px);
    }

    &.user-listing {
      width: calc(100% - 293px) !important;
    }

    &.customer-position {
      width: calc(100% - 273px);
    }

    &.customer-position-job {
      width: calc(100% - 272px);

    }

  }

  &.side-nav-open {

    .mat-mdc-paginator {
      width: calc(100% - 116px);

      &.activity {
        width: calc(100% - 160px);
      }

      &.resource-listing {
        width: calc(100% - 115px) !important;
      }

      &.management {
        width: calc(100% - 110px);
      }


      &.user-listing {
        width: calc(100% - 133px) !important;
      }

      &.dashboard-pagination {
        width: calc(100% - 111px);
      }

      &.customer-position-job {
        width: calc(100% - 109px);
      }
    }
  }
}


// this is for sigin button
.login-button-wrapper {
  .mat-mdc-unelevated-button.mat-primary {
    width: 100%;
  }
}

// switch label
.switch-wrapper {
  &.project-dialog {
    .mat-mdc-slide-toggle .mdc-form-field .mdc-label {
      margin-right: 202px !important;
    }
    &.project-edit-button{
      .mat-mdc-slide-toggle .mdc-form-field .mdc-label {
        margin-right: 200px !important;
      }
    }
  }


  &.core-team {
    .mat-mdc-slide-toggle .mdc-form-field .mdc-label {
      margin-right: 61px !important;
    }

  }

  .mat-mdc-slide-toggle .mdc-form-field .mdc-label {
    color: #000000;
    font-size: 14px;
    margin-right: 253px;
  }
}

.add-button-wrapper {
  .mdc-button__label {
    font-size: 13px;
  }
}



// this is for angular accordian
.pannel-container {
  &.generate-invoice{
    .mat-drawer-inner-container {
      width: 542px !important;
    }
  }

  &.accordian {
    .mat-drawer-inner-container {
      width: 384px;
    }

    .modal-header {
      img {
        height: 16px;
      }
    }
  }
}


.example-accordion {
  display: block;
  max-width: 500px;
}

.example-accordion-item[aria-expanded="true"] {
  display: block;

  .example-accordion-item-header {
    border-bottom: 1px solid #DFDFDF;
  }
}


.example-accordion-item[aria-expanded="false"] {
  .example-accordion-item-header {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
  }
}



.example-accordion-item {
  margin-bottom: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}


.example-accordion-item+.example-accordion-item {
  border-top: none;
}

.example-accordion-item-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.example-accordion-item-description {
  font-size: 0.85em;
}

.example-accordion-item-header,
.example-accordion-item-body {
  padding: 7px 0;
  cursor: pointer;

  mat-form-field {
    width: calc(100% - 32px);
  }
}

.example-accordion-item-header:hover {
  cursor: pointer;
}

.example-accordion-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.example-accordion-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.accordian {
  h2:hover {
    background: linear-gradient(to right, #DAEFFF 0%, #F5FBFF 78%);
    // padding: 0 0 0 8px !important;
  }
}


.content-wrapper {
  &.accordian {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 57px);
  }

  .modal-footer {
    .filter-button {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
  .disabled-button {
    pointer-events: none;
    opacity: 0.5;
  }

}


// this is for drag and drop
.candidate-form-wrapper {
  .selected-pdf-info {
    color: #00000066;

  }


  .upload-resume-input {
    padding: 9px 16px;
    border: 1px solid #C5D4DB;
    border-radius: 4px;
  }

}

// for filter modal
.example-accordion-item[aria-expanded="true"] {
  .chevron-icon{
    transform: rotate(180deg);
    transition: 200ms ease-in-out all;
  }
}



// for dropdwon 
.options-wrapper{
  max-height: 290px;
  height: 100%;
  overflow: auto;
}