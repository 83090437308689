.dashboard-wrapper {
    .mat-mdc-paginator-outer-container {
        margin-top: 8px;
    }

    .account-detail-table {
        .name {
            min-width: 160px;
        }

        .account {
            min-width: 245px;
        }

        .amount {
            min-width: 108px;

        }

        .revenue {
            min-width: 113px;

        }

        .profit {
            min-width: 89px;
        }

        .commission {
            min-width: 142px;

        }

        .expense {
            min-width: 195px;

        }
    }

    .table-border {
        border-bottom: 1px solid #C5D4DB;
    }

    .info-container {
        height: 263px;
        overflow: scroll;
      
        div:last-child {
            margin-bottom: 0;

        }

        .grey-dot {
            background-color: $grey-text;

        }

        .primary {
            background-color: #E5F5FF;
        }

        .green-clr {
            background-color: #EBFAEA;

        }


    }

    .dashboard-header-wrapper {
        .account-info {
            .icon-wrapper {
                border-radius: 71px;
                min-width: 42px;
                max-height: 42px;

            }

            background-color: white;
            border-radius: 8px;
            border: 0.5px solid #C5D4DB;
            box-shadow: 0 1px 1px 0 rgba(197, 212, 219, 0.5);

            .stepper-icon {
                height: 20px;
                width: 20px;
            }

            .material-icons-outlined {
                font-size: 20px;
            }

            .material-symbols-outlined {
                font-size: 20px;
            }
        }
    }

    .dashboard-content {
        .project-wrapper {
            background-color: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #C5D4DB;

            .project-header {
                border-bottom: 1px solid #C5D4DB;
            }

            .project-content {
                .project-state-wrapper {
                    .dot {
                        height: 8px;
                        border-radius: 50%;
                        width: 8px;

                        &.green {
                            background-color: $success-color;

                        }

                        &.orange {
                            background-color:
                                $Warning-color;

                        }

                        &.pink {
                            background-color:
                                $error-color;
                        }

                        &.primary {
                            background-color: $primary-color;
                        }
                    }


                }

                .progress-bar-wrapper {
                    .progress-bar {
                        border-radius: 55px;
                        overflow: hidden;
                    }

                    .step {
                        height: 12px;
                        border-radius: 55px;
                        display: inline-block;
                    }

                }

                .number-info {
                    .text-wrapper {

                        border-radius: 2px;
                        background-color: rgba(52, 199, 36, 0.1);
                        padding: 2px 4px;
                        width: fit-content;

                        .material-symbols-outlined {
                            color: $success-color;

                        }
                    }
                }

            }

        }

        // remove this 
        .analytics-wrapper {
            img {
                width: 665px;
            }

            // 
        }
    }
}